<template>
<div>
    <div class="kt-input-icon">
        <input
            v-model="searchTerm"
            type="text"
            style="background: transparent !important;"
            class="form-control pl-5"
            @blur="blurEventHandler($event)"
            @focus="focusEventHandler($event)"
            @keyup.13="selectPortfolio(0)"
            @keydown="showPicker = true"
        >
        <span class="kt-input-icon__icon kt-input-icon__icon--left">
            <span>
                <i class="la la-search" />
            </span>
        </span>

        <span
            v-if="selectedStaff && !onSelected"
            class="kt-input-icon__icon kt-input-icon__icon--right"
            @click.stop.prevent="clearTeacher"
        >
            <span>
                <i class="la la-close" />
            </span>
        </span>
    </div>

    <div
        v-if="staffMembers.length > 0 && showPicker"
        class="kt-widget4 p-3 mt-2"
    >
        <div
            v-for="(teacher, idx) in staffMembers"
            :key="`${idx}_${teacher.schoolStaffId}_portfolio`"
            class="kt-widget4__item"
            @click.stop.prevent="selectPortfolio(idx)"
        >
            <div class="kt-widget4__pic kt-widget4__pic--pic">
                <UserAvatar :avatar-user="teacher">
                    <div class="kt-badge kt-badge--lg kt-badge--success">
                        {{ teacher.lastName.substring(0,1) }}{{ teacher.firstName.substring(0,1) }}
                    </div>
                </UserAvatar>
            </div>

            <div class="kt-widget4__info">
                <a
                    href="#"
                    class="kt-widget4__username"
                    @click.stop.prevent="selectPortfolio(idx)"
                >
                    {{ teacher.lastName }}, {{ teacher.firstName }}
                </a>
                <p class="kt-widget4__text">
                    {{ teacher.googleEmail }}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import UserAvatar from '../../components/UserAvatar.vue';
import Types from '../../store/Types';

export default {
    name: 'BadgeManagerPicker',
    components: {
        UserAvatar,
    },
    props: {
        onSelected: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            searchTerm: '',
            selectedStaff: null,
            showPicker: false,
            timeout: null,
        };
    },
    computed: {
        ...mapState({
            teacherPortfolios: (state) => state.database.teachers,
        }),
        staffMembers() {
            const searchTerm = ('' || this.searchTerm).toLowerCase();
            const portfolios = [...this.teacherPortfolios];
            const output = portfolios.filter((t) => {
                const search = `${t.lastName}, ${t.firstName} ${t.googleEmail || ''} ${t.schoolEmail || ''}`.toLowerCase();
                return (search.indexOf(searchTerm.toLowerCase()) > -1);
            });
            return output.slice(0, 3);
        },
    },
    methods: {
        clearTeacher() {
            this.selectedStaff = null;
            this.searchTerm = '';
            if (!this.onSelected) {
                // this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STAFF, null);
                // this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
            }
        },
        blurEventHandler() {
            const v = this;
            v.timeout = setTimeout(() => {
                v.showPicker = false;
            }, 600);
        },
        focusEventHandler() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.showPicker = true;
        },
        selectPortfolio(idx) {
            this.selectedStaff = this.staffMembers[idx];
            this.showPicker = false;

            if (this.onSelected) {
                this.searchTerm = '';
                this.onSelected(this.selectedStaff);
                return;
            }
            this.searchTerm = `${this.selectedStaff.lastName}, ${this.selectedStaff.firstName}`;
            // this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STAFF, this.selectedStaff.schoolStaffId);
            // this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
    },
};
</script>

<style scoped>
.kt-widget4 {
    position: absolute;
    width: 75%;
    z-index: 2;
    background: white;
    border: 1px solid #e2e5ec;
}

div.kt-widget4__info {
    overflow-x: clip;
    width: 80%;
}
</style>
