<template>
<div class="align-items-center">
    <div class="form-group row">
        <div class="col-md-6">
            <div class="kt-form__group--inline">
                <div class="kt-form__label">
                    <label>Requirement Type</label>
                </div>
                <select
                    v-model="badgeRequirementType"
                    class="form-control"
                >
                    <option
                        v-for="t in requirementTypes"
                        :key="'type_' + t"
                        :value="t"
                        :selected="t == badgeRequirementType"
                    >
                        {{ t }}
                    </option>
                </select>
            </div>
            <div class="d-md-none kt-margin-b-10" />
        </div>
        <div class="col-md-2">
            <div class="kt-form__group--inline">
                <div class="kt-form__label">
                    <label>Operator</label>
                </div>
                <select
                    v-model="badgeRequirementOperator"
                    class="form-control"
                >
                    <option value="=">
                        =
                    </option>
                    <option value=">=">
                        &gt;=
                    </option>
                    <option value="<=">
                        &lt;=
                    </option>
                </select>
            </div>
            <div class="d-md-none kt-margin-b-10" />
        </div>
        <div class="col-md-2">
            <div class="kt-form__group--inline">
                <div class="kt-form__label">
                    <label class="kt-label m-label--single">
                        Threshold
                    </label>
                </div>
                <div class="kt-form__control">
                    <input
                        v-model="badgeRequirementThreshold"
                        type="number"
                        class="form-control"
                        placeholder="Enter positive or negative number"
                    >
                </div>
            </div>
            <div class="d-md-none kt-margin-b-10" />
        </div>
        <div class="col-md-2 pt-2">
            <a
                href="#"
                class="btn-sm btn btn-clean btn-clean-danger btn-icon mt-4 ml-3"
                @click.stop.prevent="deleteBadgeRequirement"
            >
                <i class="la la-trash-o" />
            </a>
        </div>
    </div>
    <div
        v-if="badgeRequirementType == 'Anecdotal Event Count'"
        class="form-group row"
    >
        <div class="offset-md-1 col-md-4">
            <div class="kt-form__group--inline">
                <!-- <div class="kt-form__label">
                    <label>Anecdotal Category</label>
                </div> -->
                <select
                    v-model="anecdotalEventCategoryId"
                    class="form-control"
                >
                    <option value="">
                        Select Anecdotal Category
                    </option>
                    <option
                        v-for="category in anecdotalEventCategories"
                        :key="'anecdotalEventCategoryId_' + category.anecdotalEventCategoryId"
                        :value="category.anecdotalEventCategoryId"
                        :selected="anecdotalEventCategoryId == category.anecdotalEventCategoryId"
                    >
                        {{ category.anecdotalEventCategoryTitle }}
                    </option>
                </select>
            </div>
            <div class="d-md-none kt-margin-b-10" />
        </div>
        <div class="col-md-7">
            <div class="form-group">
                <!-- <label>Scope</label> -->
                <div class="kt-radio-inline">
                    <label class="kt-radio">
                        <input
                            v-model="badgeRequirementScope"
                            type="radio"
                            value="Current Term"
                        >
                        This Term
                        <span />
                    </label>
                    <label class="kt-radio">
                        <input
                            v-model="badgeRequirementScope"
                            type="radio"
                            value="All Time"
                        >
                        All Time
                        <span />
                    </label>
                </div>
            </div>
            <div class="d-md-none kt-margin-b-10" />
        </div>
    </div>
</div>
</template>

<script lang="ts">

import { mapState } from 'vuex';
import Types from '../store/Types';

export default {
    name: 'BadgeRequirement',
    props: {
        idx: {
            type: Number,
            default: null,
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            badges: (state) => state.database.badges,
        }),
        requirementTypes() {
            return [
                'Overall Point Total',
                'Anecdotal Event Count',
                'Latest Report Card Average',
                'Report Card Course Count',
                'Latest Progress Average',
                'Progress Course Count',
            ];
        },
        anecdotalEventCategories() {
            return this.$store.state.database.anecdotalEventCategories;
        },
        req() {
            return this.$store.state.database.mutableBadge.badgeRequirements[this.idx];
        },
        badgeRequirementOperator: {
            get() {
                return this.req.badgeRequirementOperator;
            },
            set(badgeRequirementOperator) {
                const { idx } = this;
                const badgeRequirements = this.$store.state.database.mutableBadge.badgeRequirements.map((r, index) => {
                    if (index === idx) {
                        const req = { ...r };
                        req.badgeRequirementOperator = badgeRequirementOperator;
                        return req;
                    }
                    return r;
                });
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeRequirements });
            },
        },
        badgeRequirementLimiter: {
            get() {
                return this.req.badgeRequirementLimiter;
            },
            set(badgeRequirementLimiter) {
                const { idx } = this;
                const badgeRequirements = this.$store.state.database.mutableBadge.badgeRequirements.map((r, index) => {
                    if (index === idx) {
                        const req = { ...r };
                        req.badgeRequirementLimiter = badgeRequirementLimiter;
                        return req;
                    }
                    return r;
                });

                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeRequirements });
            },
        },
        badgeRequirementType: {
            get() {
                return this.req.badgeRequirementType;
            },
            set(badgeRequirementType) {
                const { idx } = this;
                const badgeRequirements = this.$store.state.database.mutableBadge.badgeRequirements.map((r, index) => {
                    if (index === idx) {
                        const req = { ...r };
                        req.badgeRequirementType = badgeRequirementType;
                        return req;
                    }
                    return r;
                });

                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeRequirements });
            },
        },
        badgeRequirementThreshold: {
            get() {
                return this.req.badgeRequirementThreshold;
            },
            set(badgeRequirementThreshold) {
                const { idx } = this;
                const badgeRequirements = this.$store.state.database.mutableBadge.badgeRequirements.map((r, index) => {
                    if (index === idx) {
                        const req = { ...r };
                        req.badgeRequirementThreshold = badgeRequirementThreshold;
                        return req;
                    }
                    return r;
                });

                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeRequirements });
            },
        },
        anecdotalEventCategoryId: {
            get() {
                return this.req.anecdotalEventCategoryId;
            },
            set(anecdotalEventCategoryId) {
                const { idx } = this;
                const badgeRequirements = this.$store.state.database.mutableBadge.badgeRequirements.map((r, index) => {
                    if (index === idx) {
                        const req = { ...r };
                        req.anecdotalEventCategoryId = anecdotalEventCategoryId;
                        return req;
                    }
                    return r;
                });

                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeRequirements });
            },
        },
        badgeRequirementScope: {
            get() {
                return this.req.badgeRequirementScope;
            },
            set(badgeRequirementScope) {
                const { idx } = this;
                const badgeRequirements = this.$store.state.database.mutableBadge.badgeRequirements.map((r, index) => {
                    if (index === idx) {
                        const req = { ...r };
                        req.badgeRequirementScope = badgeRequirementScope;
                        return req;
                    }
                    return r;
                });
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeRequirements });
            },
        },
    },
    methods: {
        deleteBadgeRequirement() {
            const { idx } = this;
            const badgeRequirements = this.$store.state.database.mutableBadge.badgeRequirements.filter((r, index) => index !== idx);
            this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeRequirements });
        },
    },
};
</script>

<style src="../css/colorSwatch.css" />

<style scoped>
    /* .row:hover {
        background-color:gray;
    } */
</style>
