<!-- eslint-disable vue/no-v-html -->

<template>
<div v-if="badgeType && !disabled" class="kt-container kt-grid__item kt-grid__item--fluid">
    <BadgeTypeDescriptions :badge-type="badgeType" />
    <div class="row">
        <div class="col-lg-12">
            <div class="kt-portlet">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <h3
                            v-if="isEdit"
                            class="kt-portlet__head-title"
                        >
                            Edit {{ badgeType }}
                        </h3>
                        <h3
                            v-else
                            class="kt-portlet__head-title"
                        >
                            Create {{ badgeType }}
                        </h3>
                    </div>
                </div>

                <div class="kt-portlet__body">
                    <div class="row w-100">
                        <div
                            class="col-lg-4 pr-4"
                            style="border-right: 1px dashed #ebedf2"
                        >
                            <!-- Groupings -->
                            <div v-if="badgeType == 'Achievement Level'" class="form-group">
                                <div class="kt-form__label">
                                    <label>Group Requirements By</label>
                                </div>
                                <select
                                    v-model="badgeLimiterType"
                                    class="form-control"
                                >
                                    <option
                                        v-for="t in badgeLimiterTypes"
                                        :key="'type_' + t.label"
                                        :value="t.value"
                                        :selected="t.value == badgeLimiterType"
                                    >
                                        {{ t.label }}
                                    </option>
                                </select>
                            </div>
                            <!-- Colors and Icons -->
                            <div class="form-group row">
                                <div class="col-xl-6 pb-2">
                                    <label class="w-100"><span class="kt-font-danger">*</span> Color</label>
                                    <div class="input-group">
                                        <ColorSwatch
                                            class="input-group-prepend"
                                            :color-change="selectBadgeColor"
                                            :color-selected="badgeColor"
                                            :item-index="0"
                                        />
                                        <input
                                            type="text"
                                            class="form-control"
                                            disabled="disabled"
                                            :value="badgeColor"
                                        >
                                    </div>
                                </div>
                                <div class="col-xl-6 pb-2">
                                    <label class="w-100"> <span class="kt-font-danger">*</span> Icon</label>
                                    <div class="input-group kt-input-icon">
                                        <EmojiPicker
                                            :initial-emoji="badgeIcon"
                                            :on-selected="emojiPicked"
                                            class="input-group-prepend"
                                        />
                                        <input
                                            type="text"
                                            class="form-control pr-5"
                                            readonly="readonly"
                                            :value="badgeIconShortName"
                                        >
                                        <span
                                            v-if="badgeIconShortName"
                                            class="kt-input-icon__icon kt-input-icon__icon--right"
                                            @click.stop.prevent="clearIcon"
                                        >
                                            <span><i class="la la-close" /></span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Share Settings -->
                            <div class="form-group pt-3">
                                <div class="kt-checkbox-list ml-2">
                                    <label
                                        v-if="badgeType !== 'Administrative Badge'"
                                        v-b-tooltip.hover.bottomLeft="'Allow guardians to see this badge'"
                                        class="kt-checkbox"
                                    >
                                        <input
                                            v-model="guardianShare"
                                            type="checkbox"
                                        >
                                        Share Guardians
                                        <span />
                                    </label>
                                    <label
                                        v-if="badgeType !== 'Administrative Badge'"
                                        v-b-tooltip.hover.bottomLeft="'Allow students to see this badge'"
                                        class="kt-checkbox"
                                    >
                                        <input
                                            v-model="studentShare"
                                            type="checkbox"
                                        >
                                        Share Students
                                        <span />
                                    </label>
                                    <label
                                        v-if="badgeType !== 'Achievement Level'"
                                        v-b-tooltip.hover.bottomLeft="'Allows students to edit avatars if they earn this badge'"
                                        class="kt-checkbox"
                                    >
                                        <input
                                            v-model="unlocksAvatars"
                                            type="checkbox"
                                        >
                                        Unlocks Student Avatar Editor
                                        <span />
                                    </label>
                                </div>
                            </div>

                            <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
                            <!-- Badge Managers -->
                            <div class="kt-section">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="w-100">
                                            Badge Managers:
                                            <span
                                                v-if="badgeManagers.length > 0"
                                                class="text-muted pull-right"
                                            >
                                                {{ badgeManagers.length }} manager{{ badgeManagers.length == 1 ? '' : 's' }}
                                            </span>
                                        </label>
                                        <BadgeManagerPicker :on-selected="managerAdded" />
                                        <div class="tab-content student-list p-3">
                                            <div class="kt-widget4">
                                                <div
                                                    v-for="(user, idx) in badgeManagers"
                                                    :key="`${idx}_badge_managers`"
                                                    class="kt-widget4__item"
                                                >
                                                    <div class="kt-widget4__info">
                                                        <a
                                                            href="#"
                                                            class="kt-widget4__username"
                                                            @click.stop.prevent="removeManagerAtIndex(idx)"
                                                        >
                                                            {{ user.lastName }}, {{ user.firstName }}
                                                        </a>
                                                        <p v-html="`${$_utilMixins_format_email(user.schoolEmail)}`" />
                                                    </div>
                                                    <a
                                                        href="#"
                                                        @click.stop.prevent="removeManagerAtIndex(idx)"
                                                    >
                                                        <i class="la la-close" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <span
                                            class="form-text text-muted"
                                            style="font-size: 0.85rem"
                                        >
                                            Managers are permitted to assign
                                            badges of this type to students.

                                            If no managers are assigned, the badge is public
                                            and all school staff will be able to use it.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="kt-section w-100 p-4 pb-0 mb-0">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label>
                                            <span class="kt-font-danger">*</span>
                                            Title
                                        </label>
                                        <input
                                            v-model="badgeTitle"
                                            type="text"
                                            class="form-control"
                                            maxlength="255"
                                            placeholder=""
                                        >
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-wide pull-right mt-4"
                                            data-dismiss="modal"
                                            :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                                            @click.stop.prevent="save"
                                        >
                                            Save {{ badgeType }}
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Description</label>
                                    <textarea
                                        v-model="badgeDescription"
                                        rows="3"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div v-if="badgeType == 'Achievement Level'" class="kt-section w-100 p-4 pb-0 mb-0">
                                <ul
                                    v-if="badgeLimiterType == 'Grade Level'"
                                    class="nav nav-pills nav-fill"
                                    role="tablist"
                                >
                                    <li
                                        v-for="(level, idx) in gradeLevels"
                                        :key="level.value"
                                        class="nav-item"
                                    >
                                        <a
                                            class="nav-link"
                                            :class="{active: level.active}"
                                            data-toggle="tab"
                                            href="#"
                                            @click.stop.prevent="selectGradeLevel(idx)"
                                        >
                                            {{ level.name }}
                                        </a>
                                    </li>
                                </ul>

                                <BadgeRequirement
                                    v-for="(req) in badgeRequirements"
                                    :key="'requirement_' + req.idx"
                                    :idx="req.idx"
                                />

                                <div class="form-group row pt-4 pb-0 mb-0">
                                    <div class="col-lg-12">
                                        <a
                                            v-if="selectedGradeLevel"
                                            href="#"
                                            class="btn btn-bold btn-sm btn-wide btn-label-brand"
                                            @click.stop.prevent="addRequirement"
                                        >
                                            <i class="la la-plus" />
                                            Add {{ activeTab.name }} Requirement
                                        </a>
                                        <a
                                            v-else
                                            href="#"
                                            class="btn btn-bold btn-sm btn-wide btn-label-brand"
                                            @click.stop.prevent="addRequirement"
                                        >
                                            <i class="la la-plus" />
                                            Add Requirement
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import 'emoji-mart-vue-fast/css/emoji-mart.css';

import _ from 'lodash';
import Types from '../store/Types';
import * as network from '../network';
import ColorSwatch from '../components/ColorSwatch.vue';
import EmojiPicker from '../components/EmojiPicker.vue';
import BadgeTypeDescriptions from '../components/BadgeTypeDescriptions.vue';
import BadgeRequirement from '../components/BadgeRequirement.vue';
import blankBadge from '../store/state/badge';
import utilMixins from '../store/mixins/utilMixins';
import BadgeManagerPicker from './anecdotals/BadgeManagerPicker.vue';

export default {
    name: 'EditBadge',
    components: {
        ColorSwatch,
        BadgeRequirement,
        BadgeManagerPicker,
        EmojiPicker,
        BadgeTypeDescriptions,
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus();
            },
        },
    },
    mixins: [utilMixins],
    data() {
        return {
            saving: false,
            gradeLevels: [],
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        badges() {
            return this.$store.state.database.badges;
        },
        disabled() {
            const {role} = this.$store.state.user.school;
            if (role !== 'School Admin') {
                if (this.badgeType !== 'Academic Award') {
                    return true;
                }
            }
            return false;
        },
        activeTab() {
            return this.gradeLevels.find((l) => l.active) || null;
        },
        selectedGradeLevel() {
            const { activeTab, badgeLimiterType } = this;
            return activeTab && badgeLimiterType ? activeTab.value : null;
        },
        badgeLimiterTypes() {
            return [{ label: 'No Grouping', value: null }, { label: 'Grade Level', value: 'Grade Level' }];
        },
        isNew() {
            return !this.isEdit;
        },
        isEdit() {
            const { badgeId } = this.$route.params;
            return this.badges.find((c) => c.badgeId == badgeId);
        },
        achievementBadge() {
            if (this.isEdit) {
                return this.badge.badgeType == 'Achievement Level';
            }
            return this.$route.query.badgeType == 'Achievement';
        },
        administrativeBadge() {
            if (this.isEdit) {
                return this.badge.badgeType == 'Administrative Badge';
            }
            return this.$route.query.badgeType == 'Administrative';
        },
        academicBadge() {
            if (this.isEdit) {
                return this.badge.badgeType == 'Academic Award';
            }
            return this.$route.query.badgeType == 'Academic';
        },
        badgeType: {
            set (newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeType: newValue });
            },
            get() {
                return this.$store.state.database.mutableBadge.badgeType;
            },
        },
        badgeTitle: {
            set: _.debounce(function (newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeTitle: newValue });
            }, 500),
            get() {
                return this.$store.state.database.mutableBadge.badgeTitle;
            },
        },
        badgeDescription: {
            set: _.debounce(function (newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeDescription: newValue });
            }, 500),
            get() {
                return this.$store.state.database.mutableBadge.badgeDescription;
            },
        },
        badgeRank: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeRank: newValue });
            },
            get() {
                return this.$store.state.database.mutableBadge.badgeRank;
            },
        },
        badgeLimiterType: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeLimiterType: newValue });
            },
            get() {
                return this.$store.state.database.mutableBadge.badgeLimiterType;
            },
        },
        badgeColor: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeColor: newValue });
            },
            get() {
                return this.$store.state.database.mutableBadge.badgeColor;
            },
        },
        badgeIcon: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeIcon: newValue });
            },
            get() {
                return this.$store.state.database.mutableBadge.badgeIcon;
            },
        },
        badgeIconShortName: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeIconShortName: newValue });
            },
            get() {
                return this.$store.state.database.mutableBadge.badgeIconShortName;
            },
        },
        guardianShare: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { guardianShare: newValue });
            },
            get() {
                return this.$store.state.database.mutableBadge.guardianShare;
            },
        },
        studentShare: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { studentShare: newValue });
            },
            get() {
                return this.$store.state.database.mutableBadge.studentShare;
            },
        },
        unlocksAvatars: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { unlocksAvatars: newValue });
            },
            get() {
                return this.$store.state.database.mutableBadge.unlocksAvatars;
            },
        },
        badgeManagers: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeManagers: newValue });
            },
            get() {
                const { teachers } = this.$store.state.database;
                return (this.$store.state.database.mutableBadge.badgeManagers || [])
                    .map((m) => teachers.find((s) => s.userId == m.userId) || null)
                    .filter((m) => m);
            },
        },
        badgeRequirements: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeRequirements: newValue });
            },
            get() {
                const { mutableBadge } = this.$store.state.database;
                const badgeRequirements = mutableBadge.badgeRequirements.map((b, idx) => {
                    const req = { ...b };
                    req.idx = idx;
                    return req;
                });
                const { selectedGradeLevel, badgeLimiterType } = this;
                if (selectedGradeLevel && badgeLimiterType == 'Grade Level') {
                    return badgeRequirements.filter((g) => g.badgeRequirementLimiter == selectedGradeLevel);
                }
                return badgeRequirements;
            },
        },
        databaseIsReady() {
            return Boolean(this.$store.state.database.loadState === 'READY');
        },
    },
    watch: {
        databaseIsReady() {
            this.populateBadge();
        },
    },
    mounted() {
        this.populateBadge();
    },
    methods: {
        selectGradeLevel(idx) {
            this.gradeLevels = this.gradeLevels.map((l, i) => {
                const row = { ...l };
                row.active = i == idx;
                return row;
            });
        },
        removeManagerAtIndex(index) {
            const managers = [
                ...this.badgeManagers.filter((m, idx) => idx !== index),
            ];
            this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeManagers: managers });
        },
        managerAdded(user) {
            const { userId } = user;
            const newManager = {
                userId,
                deleted: false,
            };
            const managers = this.badgeManagers
                .filter((m) => m.userId !== userId);
            managers.push(newManager);
            this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeManagers: managers });
        },
        emojiPicked(emoji) {
            if (!emoji || !emoji.colons) {
                this.badgeIconShortName = null;
                this.badgeIcon = null;
                return;
            }
            this.badgeIconShortName = emoji.colons;
            this.badgeIcon = emoji.native;
        },
        addRequirement() {
            const { badgeLimiterType, selectedGradeLevel } = this;
            const newRequirement = {
                badgeRequirementLimiter: badgeLimiterType == null ? null : selectedGradeLevel,
                badgeRequirementType: 'Overall Point Total',
                badgeRequirementOperator: '>=',
                badgeRequirementThreshold: 0,
                badgeRequirementScope: null,
                anecdotalEventCategoryId: null,
                deleted: false,
            };

            const { mutableBadge } = this.$store.state.database;
            mutableBadge.badgeRequirements.push(newRequirement);
            const badgeRequirements = mutableBadge.badgeRequirements.map((b, idx) => {
                const req = { ...b };
                req.idx = idx;
                return req;
            });

            this.badgeRequirements = [...badgeRequirements];
        },
        clearIcon() {
            this.badgeIcon = null;
            this.badgeIconShortName = null;
        },
        selectBadgeColor(color) {
            this.badgeColor = color;
        },
        populateBadge() {
            const { isEdit, databaseIsReady } = this;
            if (!databaseIsReady) return;
            const { schoolId } = this.user.school;
            const userId = this.$store.state.user.mappedUserId || this.$store.state.user.userId;
            if (!userId) return;

            const formatGradeLevel = this.$_utilMixins_format_grade_level;
            const allGradeLevels = this.$store.state.database.students.map((s) => s.gradeLevel);
            this.gradeLevels = [...new Set(allGradeLevels)]
                .filter((g) => g)
                .sort()
                .map((g, idx) => ({
                    active: idx == 0,
                    name: formatGradeLevel(g),
                    value: g,
                }));

            if (isEdit) {
                const { badgeId } = this.$route.params;
                const badge = this.badges.find((c) => c.badgeId == badgeId);
                if (!badge) return this.showError('Badge not found');
                badge.schoolId = schoolId;
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { ...badge });
            } else {
                const badge = { ...blankBadge };
                badge.schoolId = schoolId;
                badge.badgeType = this.$route.query.badgeType || 'Academic Award';
                badge.badgeRank += 1;
                if (badge.badgeType == 'Academic Award') {
                    badge.studentShare = true;
                    badge.guardianShare = true;
                    badge.unlocksAvatars = true;
                } else {
                    badge.studentShare = false;
                    badge.guardianShare = false;
                    badge.unlocksAvatars = false;
                }
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { ...badge });

                // private by default
                const newManager = {
                    userId,
                    deleted: false,
                };
                const managers = this.badgeManagers
                    .filter((m) => m.userId !== userId);
                managers.push(newManager);
                this.$store.commit(Types.mutations.SET_MUTABLE_BADGE, { badgeManagers: managers });

            }



            // set late watchers
            this.$watch('badgeLimiterType', () => {
                this.badgeRequirements = [];
            });

            this.$store.commit(Types.mutations.SET_STUDENT_BADGE_PANEL_CLOSE);
        },
        save() {
            const v = this;
            const { showError } = v;

            // needs validation here
            if (!this.badgeTitle) {
                return showError('Please enter a badge title');
            }
            if (!this.badgeColor) {
                return showError('Please select a badge color');
            }
            if (!this.badgeIcon) {
                return showError('Please select a badge icon');
            }

            // validate requirements
            const isNumeric = function (n) {

                return !isNaN(parseFloat(n)) && isFinite(n);
            };

            const { mutableBadge } = this.$store.state.database;

            const invalidRequirements = mutableBadge.badgeRequirements.filter((r) => {
                const threshold = r.badgeRequirementThreshold;
                if (threshold === null || threshold === '') {
                    return true;
                }
                if (!isNumeric(threshold)) return true;
                return false;
            });

            if (invalidRequirements.length > 0) {
                return showError('Each requirement must be a valid positive or negative number');
            }

            if (mutableBadge.badgeLimiterType == 'Grade Level') {
                const invalidGradeLevels = [];
                // for each gl, check for dupes
                this.gradeLevels.forEach((gradeLevel) => {
                    // create unique list of reqs for this gl
                    // if not the same length, then there are dupes
                    const gradeLevelRequirements = mutableBadge.badgeRequirements
                        .filter((r) => r.badgeRequirementLimiter == gradeLevel.value);

                    const reqs = gradeLevelRequirements.map((r) => {
                        const badgeRequirementLimiter = r.badgeRequirementLimiter || '0';
                        const anecdotalEventCategoryId = r.anecdotalEventCategoryId || '0';
                        return `${badgeRequirementLimiter}_${r.badgeRequirementType}_${anecdotalEventCategoryId}`;
                    });

                    const uniqueGradeLevelRequirements = [...new Set(reqs)];
                    if (uniqueGradeLevelRequirements.length != reqs.length) {
                        invalidGradeLevels.push(gradeLevel.value);
                    }
                });

                if (invalidGradeLevels.length > 0) {
                    return showError(`Each grade level must have unique requirement types. Grade levels '${invalidGradeLevels.join(', ')}' have duplicate requirements`);
                }
            } else {
                const reqs = mutableBadge.badgeRequirements.map((r) => {
                    const badgeRequirementLimiter = r.badgeRequirementLimiter || '0';
                    const anecdotalEventCategoryId = r.anecdotalEventCategoryId || '0';
                    return `${badgeRequirementLimiter}_${r.badgeRequirementType}_${anecdotalEventCategoryId}`;
                });

                const uniqueRequirements = [...new Set(reqs)];
                if (uniqueRequirements.length != reqs.length) {
                    return showError('Each requirement type must be unique');
                }
            }

            if (this.saving) return;
            this.saving = true;

            const badge = {
                badgeTitle: this.badgeTitle,
                badgeType: this.badgeType,
                badgeDescription: this.badgeDescription || null,
                badgeRank: this.badgeRank,
                badgeColor: this.badgeColor,
                badgeIcon: this.badgeIcon,
                badgeLimiterType: this.badgeLimiterType,
                badgeIconShortName: this.badgeIconShortName,
                guardianShare: this.guardianShare,
                studentShare: this.studentShare,
                unlocksAvatars: this.unlocksAvatars,
                badgeRequirements: this.badgeRequirements,
                hasRequirements: this.badgeRequirements.length > 0,
                badgeManagers: this.badgeManagers.map((m) => ({
                    userId: m.userId,
                    deleted: false,
                })),
            };

            const { schoolId, schoolTermId } = this.user.school;
            if (this.isEdit) {
                const { badgeId } = this.$route.params;
                const newBadge = {
                    badgeId,
                    deleted: false,
                    ...badge,
                };

                newBadge.badgeManagers = newBadge.badgeManagers.map((m) => ({
                    badgeId,
                    ...m,
                }));

                newBadge.badgeRequirements = newBadge.badgeRequirements.map((m) => ({
                    badgeId,
                    ...m,
                }));

                network.badges.editBadge({ url: { schoolId, schoolTermId, badgeId }, body: { badge: newBadge } }, (err, results) => {
                    this.saving = false;
                    if (err) return v.showError(err);
                    const { badges } = results;
                    const createdBadge = badges.find((c) => c.badgeId == badgeId);
                    if (!createdBadge) return v.showError('Badge not found');
                    v.$store.commit(Types.mutations.EDIT_BADGE, { badge: createdBadge });
                    this.$router.push({ name: 'BadgeSetup', query: { badgeType: createdBadge.badgeType }});
                });
            } else {
                network.badges.addBadgeForSchool({ url: { schoolId, schoolTermId }, body: { badge } }, (err, results) => {
                    this.saving = false;
                    if (err) return v.showError(err);
                    const { badges } = results;
                    const [createdBadge] = badges;
                    if (!createdBadge) return v.showError('Badge not found');
                    v.$store.commit(Types.mutations.ADD_BADGE, { badge: createdBadge });
                    this.$router.push({ name: 'BadgeSetup', query: { badgeType: createdBadge.badgeType }});
                });
            }
        },
    },
};
</script>

<style src="../css/colorSwatch.css" />

<style scoped>
.emoji-mart {
    border: none !important;
    max-height: 300px;
}

.media-body {
    width: 200px;
}

.big-category {
    display: block;
    width: 132px;
    overflow: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.point-container {
    width: 80px;
    font-weight: 600;
    font-size: 1.8rem;
}
</style>
