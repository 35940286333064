import { render, staticRenderFns } from "./BadgeRequirement.vue?vue&type=template&id=c341e660&scoped=true"
import script from "./BadgeRequirement.vue?vue&type=script&lang=ts"
export * from "./BadgeRequirement.vue?vue&type=script&lang=ts"
import style0 from "../css/colorSwatch.css?vue&type=style&index=0&lang=css&external"
import style1 from "./BadgeRequirement.vue?vue&type=style&index=1&id=c341e660&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c341e660",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c341e660')) {
      api.createRecord('c341e660', component.options)
    } else {
      api.reload('c341e660', component.options)
    }
    module.hot.accept("./BadgeRequirement.vue?vue&type=template&id=c341e660&scoped=true", function () {
      api.rerender('c341e660', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BadgeRequirement.vue"
export default component.exports