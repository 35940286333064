import { render, staticRenderFns } from "./EditBadge.vue?vue&type=template&id=5d51848a&scoped=true"
import script from "./EditBadge.vue?vue&type=script&lang=ts"
export * from "./EditBadge.vue?vue&type=script&lang=ts"
import style0 from "../css/colorSwatch.css?vue&type=style&index=0&lang=css&external"
import style1 from "./EditBadge.vue?vue&type=style&index=1&id=5d51848a&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d51848a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d51848a')) {
      api.createRecord('5d51848a', component.options)
    } else {
      api.reload('5d51848a', component.options)
    }
    module.hot.accept("./EditBadge.vue?vue&type=template&id=5d51848a&scoped=true", function () {
      api.rerender('5d51848a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rootComponents/EditBadge.vue"
export default component.exports