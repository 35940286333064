var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.badgeType && !_vm.disabled
    ? _c(
        "div",
        { staticClass: "kt-container kt-grid__item kt-grid__item--fluid" },
        [
          _c("BadgeTypeDescriptions", {
            attrs: { "badge-type": _vm.badgeType },
          }),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "kt-portlet" }, [
                _c("div", { staticClass: "kt-portlet__head" }, [
                  _c("div", { staticClass: "kt-portlet__head-label" }, [
                    _vm.isEdit
                      ? _c("h3", { staticClass: "kt-portlet__head-title" }, [
                          _vm._v(" Edit " + _vm._s(_vm.badgeType) + " "),
                        ])
                      : _c("h3", { staticClass: "kt-portlet__head-title" }, [
                          _vm._v(" Create " + _vm._s(_vm.badgeType) + " "),
                        ]),
                  ]),
                ]),
                _c("div", { staticClass: "kt-portlet__body" }, [
                  _c("div", { staticClass: "row w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-4 pr-4",
                        staticStyle: { "border-right": "1px dashed #ebedf2" },
                      },
                      [
                        _vm.badgeType == "Achievement Level"
                          ? _c("div", { staticClass: "form-group" }, [
                              _vm._m(0),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.badgeLimiterType,
                                      expression: "badgeLimiterType",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.badgeLimiterType = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                _vm._l(_vm.badgeLimiterTypes, function (t) {
                                  return _c(
                                    "option",
                                    {
                                      key: "type_" + t.label,
                                      domProps: {
                                        value: t.value,
                                        selected:
                                          t.value == _vm.badgeLimiterType,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(t.label) + " ")]
                                  )
                                }),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-xl-6 pb-2" }, [
                            _vm._m(1),
                            _c(
                              "div",
                              { staticClass: "input-group" },
                              [
                                _c("ColorSwatch", {
                                  staticClass: "input-group-prepend",
                                  attrs: {
                                    "color-change": _vm.selectBadgeColor,
                                    "color-selected": _vm.badgeColor,
                                    "item-index": 0,
                                  },
                                }),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: { type: "text", disabled: "disabled" },
                                  domProps: { value: _vm.badgeColor },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-6 pb-2" }, [
                            _vm._m(2),
                            _c(
                              "div",
                              { staticClass: "input-group kt-input-icon" },
                              [
                                _c("EmojiPicker", {
                                  staticClass: "input-group-prepend",
                                  attrs: {
                                    "initial-emoji": _vm.badgeIcon,
                                    "on-selected": _vm.emojiPicked,
                                  },
                                }),
                                _c("input", {
                                  staticClass: "form-control pr-5",
                                  attrs: { type: "text", readonly: "readonly" },
                                  domProps: { value: _vm.badgeIconShortName },
                                }),
                                _vm.badgeIconShortName
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-input-icon__icon kt-input-icon__icon--right",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.clearIcon.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._m(3)]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group pt-3" }, [
                          _c("div", { staticClass: "kt-checkbox-list ml-2" }, [
                            _vm.badgeType !== "Administrative Badge"
                              ? _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.bottomLeft",
                                        value:
                                          "Allow guardians to see this badge",
                                        expression:
                                          "'Allow guardians to see this badge'",
                                        modifiers: {
                                          hover: true,
                                          bottomLeft: true,
                                        },
                                      },
                                    ],
                                    staticClass: "kt-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.guardianShare,
                                          expression: "guardianShare",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.guardianShare
                                        )
                                          ? _vm._i(_vm.guardianShare, null) > -1
                                          : _vm.guardianShare,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.guardianShare,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.guardianShare = $$a.concat(
                                                  [$$v]
                                                ))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.guardianShare = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.guardianShare = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" Share Guardians "),
                                    _c("span"),
                                  ]
                                )
                              : _vm._e(),
                            _vm.badgeType !== "Administrative Badge"
                              ? _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.bottomLeft",
                                        value:
                                          "Allow students to see this badge",
                                        expression:
                                          "'Allow students to see this badge'",
                                        modifiers: {
                                          hover: true,
                                          bottomLeft: true,
                                        },
                                      },
                                    ],
                                    staticClass: "kt-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.studentShare,
                                          expression: "studentShare",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(_vm.studentShare)
                                          ? _vm._i(_vm.studentShare, null) > -1
                                          : _vm.studentShare,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.studentShare,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.studentShare = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.studentShare = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.studentShare = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" Share Students "),
                                    _c("span"),
                                  ]
                                )
                              : _vm._e(),
                            _vm.badgeType !== "Achievement Level"
                              ? _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.bottomLeft",
                                        value:
                                          "Allows students to edit avatars if they earn this badge",
                                        expression:
                                          "'Allows students to edit avatars if they earn this badge'",
                                        modifiers: {
                                          hover: true,
                                          bottomLeft: true,
                                        },
                                      },
                                    ],
                                    staticClass: "kt-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.unlocksAvatars,
                                          expression: "unlocksAvatars",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.unlocksAvatars
                                        )
                                          ? _vm._i(_vm.unlocksAvatars, null) >
                                            -1
                                          : _vm.unlocksAvatars,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.unlocksAvatars,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.unlocksAvatars =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.unlocksAvatars = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.unlocksAvatars = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" Unlocks Student Avatar Editor "),
                                    _c("span"),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", {
                          staticClass:
                            "kt-separator kt-separator--border-dashed kt-separator--space-lg",
                        }),
                        _c("div", { staticClass: "kt-section" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("label", { staticClass: "w-100" }, [
                                  _vm._v(" Badge Managers: "),
                                  _vm.badgeManagers.length > 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "text-muted pull-right",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.badgeManagers.length) +
                                              " manager" +
                                              _vm._s(
                                                _vm.badgeManagers.length == 1
                                                  ? ""
                                                  : "s"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("BadgeManagerPicker", {
                                  attrs: { "on-selected": _vm.managerAdded },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-content student-list p-3",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "kt-widget4" },
                                      _vm._l(
                                        _vm.badgeManagers,
                                        function (user, idx) {
                                          return _c(
                                            "div",
                                            {
                                              key: `${idx}_badge_managers`,
                                              staticClass: "kt-widget4__item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-widget4__info",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "kt-widget4__username",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          $event.preventDefault()
                                                          return _vm.removeManagerAtIndex(
                                                            idx
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            user.lastName
                                                          ) +
                                                          ", " +
                                                          _vm._s(
                                                            user.firstName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("p", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        `${_vm.$_utilMixins_format_email(
                                                          user.schoolEmail
                                                        )}`
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.removeManagerAtIndex(
                                                        idx
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "la la-close",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "form-text text-muted",
                                    staticStyle: { "font-size": "0.85rem" },
                                  },
                                  [
                                    _vm._v(
                                      " Managers are permitted to assign badges of this type to students. If no managers are assigned, the badge is public and all school staff will be able to use it. "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "col-lg-8" }, [
                      _c(
                        "div",
                        { staticClass: "kt-section w-100 p-4 pb-0 mb-0" },
                        [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "div",
                              { staticClass: "col-lg-6 col-md-6 col-sm-6" },
                              [
                                _vm._m(4),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.badgeTitle,
                                      expression: "badgeTitle",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    maxlength: "255",
                                    placeholder: "",
                                  },
                                  domProps: { value: _vm.badgeTitle },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.badgeTitle = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-lg-6 col-md-6 col-sm-6" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary btn-wide pull-right mt-4",
                                    class: {
                                      "kt-spinner kt-spinner--sm kt-spinner--light":
                                        _vm.saving,
                                    },
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.save.apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Save " + _vm._s(_vm.badgeType) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Description")]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.badgeDescription,
                                  expression: "badgeDescription",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { rows: "3" },
                              domProps: { value: _vm.badgeDescription },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.badgeDescription = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                      _vm.badgeType == "Achievement Level"
                        ? _c(
                            "div",
                            { staticClass: "kt-section w-100 p-4 pb-0 mb-0" },
                            [
                              _vm.badgeLimiterType == "Grade Level"
                                ? _c(
                                    "ul",
                                    {
                                      staticClass: "nav nav-pills nav-fill",
                                      attrs: { role: "tablist" },
                                    },
                                    _vm._l(
                                      _vm.gradeLevels,
                                      function (level, idx) {
                                        return _c(
                                          "li",
                                          {
                                            key: level.value,
                                            staticClass: "nav-item",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "nav-link",
                                                class: { active: level.active },
                                                attrs: {
                                                  "data-toggle": "tab",
                                                  href: "#",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.selectGradeLevel(
                                                      idx
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(level.name) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._l(_vm.badgeRequirements, function (req) {
                                return _c("BadgeRequirement", {
                                  key: "requirement_" + req.idx,
                                  attrs: { idx: req.idx },
                                })
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group row pt-4 pb-0 mb-0",
                                },
                                [
                                  _c("div", { staticClass: "col-lg-12" }, [
                                    _vm.selectedGradeLevel
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-bold btn-sm btn-wide btn-label-brand",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.addRequirement.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "la la-plus",
                                            }),
                                            _vm._v(
                                              " Add " +
                                                _vm._s(_vm.activeTab.name) +
                                                " Requirement "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-bold btn-sm btn-wide btn-label-brand",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.addRequirement.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "la la-plus",
                                            }),
                                            _vm._v(" Add Requirement "),
                                          ]
                                        ),
                                  ]),
                                ]
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-form__label" }, [
      _c("label", [_vm._v("Group Requirements By")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "w-100" }, [
      _c("span", { staticClass: "kt-font-danger" }, [_vm._v("*")]),
      _vm._v(" Color"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "w-100" }, [
      _c("span", { staticClass: "kt-font-danger" }, [_vm._v("*")]),
      _vm._v(" Icon"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "la la-close" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("span", { staticClass: "kt-font-danger" }, [_vm._v("*")]),
      _vm._v(" Title "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }